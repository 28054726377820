@import "./themes/theme.less";

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

.login-style(@rate) {
    .bg {
        width: 100%;
        height: 100%;
        background: url(./img/bg.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .container {
            width: 1533px*@rate;
            height: 865px*@rate;

            .title {
                text-align: center;
                font-size: 60px*@rate;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 200px*@rate;

                .logo-name {
                    color: #ACDEFA;
                }

                .system-name {
                    color: #00FFFF;
                }
            }

            .content {
                width: 100%;
                height: 665px*@rate;
                display: flex;

                .colomn {
                    width: ((1533px*@rate)-(60px*@rate))/4;

                    &:nth-child(n+2) {
                        margin-left: 20px*@rate;
                    }

                    .card {
                        width: 100%;
                        cursor: pointer;
                        position: relative;
                        border: 1px solid #7DA9FF;
                        border-radius: 20px*@rate;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:hover {
                            border-color: #FFF;

                            .angle {
                                border-color: #FFF;
                            }
                        }

                        &.item-1 {
                            background: linear-gradient(0deg, rgba(14, 44, 113, 0.5), rgba(44, 72, 158, 0.5));

                            .item-container {
                                .logo {
                                    content: url(./img/大田监测.png);
                                }

                                .main-text {
                                    color: #5F8AE0;
                                }
                            }
                        }

                        &.item-2 {
                            background: linear-gradient(0deg, rgba(14, 113, 72, 0.5), rgba(44, 158, 76, 0.5));

                            .item-container {
                                .logo {
                                    content: url(./img/大田视频监控.png);
                                }

                                .main-text {
                                    color: #5FDFE0;
                                }
                            }
                        }

                        &.item-3 {
                            background: linear-gradient(0deg, rgba(72, 14, 113, 0.5), rgba(76, 40, 167, 0.5));

                            .item-container {
                                .logo {
                                    content: url(./img/质量安全溯源.png);
                                }

                                .main-text {
                                    color: #9482F3;
                                }
                            }
                        }

                        &.item-4 {
                            background: linear-gradient(0deg, rgba(113, 14, 65, 0.5), rgba(158, 44, 73, 0.5));

                            .item-container {
                                .logo {
                                    content: url(./img/数据智慧分析.png);
                                }

                                .main-text {
                                    color: #DC5FE0;
                                }
                            }
                        }

                        &.item-5 {
                            background: linear-gradient(0deg, rgba(14, 44, 113, 0.5), rgba(44, 72, 158, 0.5));

                            .item-container {
                                .logo {
                                    content: url(./img/数字农业.png);
                                }

                                .main-text {
                                    color: #5F8AE0;
                                }
                            }
                        }


                        &.full {
                            height: 100%;

                            .item-container {
                                width: 320px*@rate;

                                .logo {
                                    width: calc(100% - 80px*@rate);
                                    margin: 0 auto;
                                }

                                .sub-text {
                                    margin-top: 20px*@rate;
                                    font-size: 50px*@rate;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    text-align: center;
                                    color: #FCF8E3;
                                }

                                .main-text {
                                    margin-top: 30px*@rate;
                                    font-size: 36px*@rate;
                                    line-height: 30px*@rate;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    text-align: center;
                                }
                            }
                        }

                        &.half {
                            height: calc(50% - 25px*@rate);

                            &:nth-child(n+2) {
                                margin-top: 50px*@rate;
                            }

                            .item-container {
                                width: 320px*@rate;
                                height: calc(100% - 40px*@rate);

                                .logo {
                                    height: calc(100% - 75px*@rate);
                                    margin: 0 auto;
                                }

                                .sub-text {
                                    font-size: 36px*@rate;
                                    line-height: 40px*@rate;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    text-align: center;
                                    color: #FCF8E3;
                                }

                                .main-text {
                                    margin-top: 5px*@rate;
                                    font-size: 30px*@rate;
                                    height: 30px*@rate;
                                    line-height: 30px*@rate;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    text-align: center;
                                }
                            }
                        }

                        .angle {
                            position: absolute;
                            width: 40px*@rate;
                            height: 40px*@rate;
                            border: 4px solid #7DA9FF;

                            &.left-top {
                                left: -2px;
                                top: -2px;
                                border-width: 4px 0 0 4px;
                                border-top-left-radius: 20px*@rate;
                            }

                            &.right-top {
                                right: -2px;
                                top: -2px;
                                border-width: 4px 4px 0 0;
                                border-top-right-radius: 20px*@rate;
                            }

                            &.left-bottom {
                                left: -2px;
                                bottom: -2px;
                                border-width: 0 0 4px 4px;
                                border-bottom-left-radius: 20px*@rate;
                            }

                            &.right-bottom {
                                right: -2px;
                                bottom: -2px;
                                border-width: 0 4px 4px 0;
                                border-bottom-right-radius: 20px*@rate;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width:0px) {
    .login-style(0.38);
}

@media screen and (min-width:800px) {
    .login-style(0.42);
}

@media screen and (min-width:1024px) {
    .login-style(0.53);
}

@media screen and (min-width:1280px) {
    .login-style(0.67);
}

@media screen and (min-width:1360px) {
    .login-style(0.71);
}

@media screen and (min-width:1440px) {
    .login-style(0.75);
}

@media screen and (min-width:1600px) {
    .login-style(0.86);
}

@media screen and (min-width:1920px) {
    .login-style(1);
}